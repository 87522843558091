/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Type } from "class-transformer";
import { IsArray, IsEmail, IsNumber, IsString, IsBoolean } from "@wog/mol-lib-api-contract/utils";
import { ArrayMaxSize } from "class-validator";
import {
	IsOptional,
	ValidateNested,
	IsEnum,
	IsString as IsStringIterable,
	ValidateIf,
	Validate,
	ValidatorConstraint,
	ValidatorConstraintInterface,
	ValidationArguments,
} from "class-validator";
import { CustomObject } from "app/modules/acp/hooks/useAcpForm/type";
import { IsAcpObjectItem, IsACPContact } from "app/common/validators";
import { acpV3PropsMaxLength } from "app/common/constant/acp";

//Define enum
enum FearType {
	PHYSICAL_PAIN = "PHYSICAL_PAIN",
	COST = "COST",
	IMPACT = "IMPACT",
	LOSS_OF_INDEPENDENCE = "LOSS_OF_INDEPENDENCE",
	ALONE = "ALONE",
	OTHERS = "FEAR_OTHERS",
}

enum ConcernType {
	CONCERNS = "CONCERNS",
	FAMILY_MEDICAL_ISSUE = "FAMILY_MEDICAL_ISSUE",
	MEDICAL_CONDITION = "MEDICAL_CONDITION",
	ALLERGY = "ALLERGY",
	PAST_HEALTH_CONDITION = "PAST_HEALTH_CONDITION",
	OTHERS = "OTHERS",
}

enum HasHealthConcernValues {
	YES = "YES",
	NO = "NO",
}

enum HasExperienceValues {
	YES = "YES",
	NO = "NO",
}

enum GoalsOfCare {
	COMFORT_FOCUSED = "COMFORT_FOCUSED",
	LIMITED_INTERVENTION = "LIMITED_INTERVENTION",
	LIFE_SUSTAINING = "LIFE_SUSTAINING",
}

enum nhsRelationship {
	PARENT = "Parent",
	BROTHER = "Brother",
	SISTER = "Sister",
	SPOUSE = "Spouse",
	SON = "Son",
	SON_IN_LAW = "Son-in-law",
	DAUGHTER = "Daughter",
	DAUGHTER_IN_LAW = "Daughter-in-law",
	OTHER = "Other",
}

//Define custom constrains and decorator
@ValidatorConstraint({ name: "customText", async: false })
class NhsCustomTextLength implements ValidatorConstraintInterface {
	validate(value: string, args: ValidationArguments) {
		const fearsType = args.object["fearType"];
		if (fearsType === FearType.OTHERS && value.length > acpV3PropsMaxLength.fears.others.description) {
			return false;
		}
		if (fearsType !== FearType.OTHERS && value.length > acpV3PropsMaxLength.fears.description) {
			return false;
		}
		return true;
	}

	defaultMessage(args: ValidationArguments) {
		return `${args.property} value must be less than ${
			args.object["fearType"] === FearType.OTHERS
				? acpV3PropsMaxLength.fears.others.description
				: acpV3PropsMaxLength.fears.description
		} char.`;
	}
}

@ValidatorConstraint({ name: "validOtherRelationship", async: false })
class ValidOtherRelationship implements ValidatorConstraintInterface {
	validate(value: any, args: ValidationArguments) {
		const object = args.object as NhsDetailsDto;
		if (value && object.relationship !== nhsRelationship.OTHER) {
			return false;
		}
		return true; // valid in all other cases
	}

	defaultMessage() {
		return `relationship value must be Other if otherRelationship has value.`;
	}
}

//details props type defines
class ConcernsDto {
	@IsEnum(ConcernType)
	concernType!: ConcernType;

	@IsString({ isOptional: true, maxLength: acpV3PropsMaxLength.healthConcerns.description })
	description?: string;
}

class FearsItemDto {
	@IsEnum(FearType)
	fearType!: FearType;

	@IsString({ isOptional: true, maxLength: acpV3PropsMaxLength.fears.others.title })
	othersType?: string;

	@Validate(NhsCustomTextLength)
	@IsString({ isOptional: true })
	description?: string;

	@IsBoolean({ isOptional: true })
	isSelectedOther?: boolean;

	@IsString({ isOptional: true })
	id?: string;
}

class TopFearsDto {
	@IsString({ isOptional: true })
	label?: string;

	@IsString({ isOptional: true })
	value?: string;
}

class NhsDetailsDto {
	@IsString({ isOptional: true })
	name?: string;

	@IsString({ isOptional: true })
	dob?: string;

	@IsEnum(nhsRelationship)
	@ValidateIf((nhsDetail) => nhsDetail.relationship !== "")
	relationship?: nhsRelationship;

	@IsString({ maxLength: acpV3PropsMaxLength.nhs.email })
	@IsOptional()
	@ValidateIf((nhsDetail) => nhsDetail.email !== "")
	email?: string;

	@Validate(ValidOtherRelationship)
	@IsString({ isOptional: true })
	otherRelationship?: string;

	@IsString({ isOptional: true, maxLength: acpV3PropsMaxLength.nhs.mobile })
	mobile?: string;
}

export class CustomInputObject {
	[key: string]: string | string[] | CustomObject;
}

//props defines
class FearsDto {
	@ValidateNested({ each: true, always: true })
	@Type(() => FearsItemDto)
	fears!: FearsItemDto[];

	@ValidateNested({ each: true, always: true })
	@ArrayMaxSize(acpV3PropsMaxLength.fears.topFears)
	@Type(() => TopFearsDto)
	ranking!: TopFearsDto[];
}

class HealthConcernsDto {
	@ValidateIf((healthConcerns) => healthConcerns.isConcerned !== "")
	@IsEnum(HasHealthConcernValues)
	isConcerned!: HasHealthConcernValues;

	@ValidateNested({ each: true, always: true })
	@Type(() => ConcernsDto)
	concerns!: ConcernsDto[];
}

class NhsDto {
	@ValidateNested({ each: true, always: true })
	@Type(() => NhsDetailsDto)
	spokesPersons!: NhsDetailsDto[];
}

class NhsAdditionalRequestDto {
	@IsString({ isOptional: true, maxLength: acpV3PropsMaxLength.nhsAdditionalRequests.request })
	request?: string;
}

export class PersonalDetailsDto {
	@IsString({ isOptional: true })
	name?: string;

	@IsString({ isOptional: true })
	uinfin?: string;

	@IsString({ isOptional: true })
	dob?: string;

	@IsString({ isOptional: true })
	sex?: string;

	@IsString({ isOptional: true, maxLength: acpV3PropsMaxLength.personalDetails.mobileNo })
	@IsOptional()
	mobileNo?: string;

	@IsEmail({ maxLength: acpV3PropsMaxLength.personalDetails.email })
	email?: string;
}

class PersonalExperiencesDto {
	@ValidateIf((personalExperiences) => personalExperiences.hasExperiences !== "")
	@IsEnum(HasExperienceValues)
	hasExperiences!: HasExperienceValues;

	@IsString({ isOptional: true, maxLength: acpV3PropsMaxLength.personalExperiences.statement })
	statement?: string;
}

class SourcesOfSupportDto {
	@IsArray()
	@IsStringIterable({ each: true })
	sources!: string[];

	@IsString({ isOptional: true, maxLength: acpV3PropsMaxLength.sourceOfSupport.description })
	description?: string;

	@IsAcpObjectItem(acpV3PropsMaxLength.sourceOfSupport.otherSource)
	@Type(() => CustomInputObject)
	@IsOptional()
	@ValidateIf((sourcesOfSupport) => sourcesOfSupport.otherSources !== "")
	otherSources?: CustomInputObject;
}

class ThingsYouEnjoyInLifeDto {
	@IsArray()
	@IsStringIterable({ each: true })
	@IsOptional()
	selections?: string[];

	@IsAcpObjectItem(acpV3PropsMaxLength.thingsYouEnjoyInLife)
	@Type(() => CustomInputObject)
	@IsOptional()
	@ValidateIf((thingsYouEnjoyInLife) => thingsYouEnjoyInLife.selectionsDetail !== "")
	selectionsDetail?: CustomInputObject;
}

class CarePreferencesDto {
	@IsArray()
	@IsStringIterable({ each: true })
	@IsOptional()
	selections?: string[];

	@IsAcpObjectItem(acpV3PropsMaxLength.carePreferences)
	@Type(() => CustomInputObject)
	@IsOptional()
	@ValidateIf((carePreference) => carePreference.selectionsDetail !== "")
	selectionsDetail?: CustomInputObject;
}

class GoalsOfCareDto {
	@ValidateIf((goalsOfCare) => goalsOfCare.goalsOfCare !== "")
	@IsEnum(GoalsOfCare)
	goalsOfCare?: GoalsOfCare;

	@IsString({ isOptional: true, maxLength: 250 })
	description?: string;

	@IsString({ isOptional: true, maxLength: 250 })
	outcomesToAvoid?: string;
}

export class DraftContentDto {
	@IsNumber()
	currentStep!: number;

	@IsNumber()
	latestStep!: number;

	@IsBoolean()
	suitabilityCheck!: boolean;

	@ValidateNested({ each: true })
	@Type(() => FearsDto)
	@IsOptional()
	fears?: FearsDto;

	@ValidateNested({ each: true })
	@Type(() => HealthConcernsDto)
	@IsOptional()
	healthConcerns?: HealthConcernsDto;

	@ValidateNested({ each: true })
	@Type(() => NhsDto)
	@IsOptional()
	nhs?: NhsDto;

	@ValidateNested({ each: true })
	@Type(() => NhsAdditionalRequestDto)
	@IsOptional()
	nhsAdditionalRequests?: NhsAdditionalRequestDto;

	@ValidateNested({ each: true })
	@Type(() => PersonalDetailsDto)
	@IsOptional()
	personalDetails?: PersonalDetailsDto;

	@ValidateNested({ each: true })
	@Type(() => PersonalExperiencesDto)
	@IsOptional()
	personalExperiences?: PersonalExperiencesDto;

	@ValidateNested({ each: true })
	@Type(() => SourcesOfSupportDto)
	@IsOptional()
	sourcesOfSupport?: SourcesOfSupportDto;

	@ValidateNested({ each: true })
	@Type(() => ThingsYouEnjoyInLifeDto)
	@IsOptional()
	thingsYouEnjoyInLife?: ThingsYouEnjoyInLifeDto;

	@ValidateNested({ each: true })
	@Type(() => GoalsOfCareDto)
	@IsOptional()
	goalsOfCare?: GoalsOfCareDto;

	@ValidateNested({ each: true })
	@Type(() => CarePreferencesDto)
	@IsOptional()
	carePreferences?: CarePreferencesDto;
}

export class AcpSaveDraftDto {
	@ValidateNested({ each: true, always: true })
	@Type(() => DraftContentDto)
	@IsACPContact()
	draftContent!: DraftContentDto;
}
